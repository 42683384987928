import Router from 'next/router'

import { locationHasMenu } from '@helpers/location'
import ROUTES from 'helpers/routes'
import { handleAddressChecks, locationFetcher, handleAddressSubmissionChange } from 'redux/action-wrappers/location'
import { setAlertTypeVisibility } from 'redux/alert/actions'
import { alertTypes } from 'redux/alert/config/types'
import { isLoggedIn } from 'redux/auth/selectors'
import { fetchCart } from 'redux/cart/actions'
import { addressLoaded, addressLoading } from 'redux/loading/actions'

export function handleHomeLocation(homeLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const placeId = homeLocation.id

    dispatch(addressLoading())
    dispatch(setAlertTypeVisibility(alertTypes.LOCATION_OUT_OF_SERVICE, true))
    dispatch(setAlertTypeVisibility(alertTypes.INVALID_STREET_ADDRESS, true))

    const address = await locationFetcher(placeId)
    dispatch(addressLoaded())

    const isDeliverableAddress = await handleAddressChecks(address, dispatch, 'home_blog_page')
    if (!isDeliverableAddress) return

    dispatch(handleAddressSubmissionChange(address))
    const hasMenu = locationHasMenu(address)

    if (isLoggedIn(state)) {
      dispatch(fetchCart())
    }

    if (address.depot !== null && hasMenu) {
      Router.push(state.addressModal.redirectLocation || ROUTES.MENU)
    }
  }
}
