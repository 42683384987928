import { css } from '@emotion/react'
import styled from '@emotion/styled'

import AutoComplete from '@/stories/autocomplete'
import { Title } from '@/stories/typography'
import { breakpoint, lightTheme } from '@/theme'

import rebrandTheme from './rebrand-address-theme.module.scss'

import PropTypes from 'prop-types'

const LocationForm = ({
  activeLocation,
  addressLoading,
  formLocation,
  onLocationChange,
  residentialOnlyError,
  showTitleDecoration,
  secondaryTitle,
  subtitle,
  title,
  useLargeContainer
}) => {
  const handleAutocompleteSubmit = (suggestion) => {
    onLocationChange(suggestion)
  }

  return (
    <Container formLocation={formLocation} useLargeContainer={useLargeContainer}>
      <StyledTitle formLocation={formLocation} showTitleDecoration={showTitleDecoration}>
        {title}
        {secondaryTitle && (
          <span>
            <br />
            {secondaryTitle}
          </span>
        )}
      </StyledTitle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <AutoComplete
        addressLoading={addressLoading}
        value={activeLocation?.title}
        onSubmitSuggestion={handleAutocompleteSubmit}
        theme={rebrandTheme}
        containerStyle={autocompleteContainerStyle}
        residentialOnlyError={residentialOnlyError}
        formLocation={formLocation}
      />
    </Container>
  )
}

LocationForm.propTypes = {
  activeLocation: PropTypes.shape({
    title: PropTypes.string
  }),
  addressLoading: PropTypes.bool,
  formLocation: PropTypes.string,
  onLocationChange: PropTypes.func,
  residentialOnlyError: PropTypes.bool,
  showTitleDecoration: PropTypes.bool,
  secondaryTitle: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  useLargeContainer: PropTypes.bool
}

LocationForm.defaultProps = {
  activeLocation: {},
  showTitleDecoration: false,
  useLargeContainer: false
}

const StyledTitle = styled(Title)<{ formLocation: string; showTitleDecoration: boolean }>`
  ${({ formLocation, showTitleDecoration }) =>
    showTitleDecoration &&
    `
    padding: ${formLocation === 'footer' ? `0 25px 20px` : `0 0 20px`};
    background-image: url(/static/images/bg-circled-overlay-min.png);
    background-position: ${formLocation === 'footer' ? '50% calc(100% - 22px);' : 'calc(50% + 77px) calc(50% + 10px);'}
    background-repeat: no-repeat;
    background-size: ${formLocation === 'footer' ? '105px auto' : '130px auto'};

    @media (min-width: ${breakpoint.min.sm}px) {
      background-position: ${
        formLocation === 'footer' ? '50% calc(50% + 22px);' : 'calc(50% + 125px) calc(50% + 25px);'
      }
      background-size: ${formLocation === 'footer' ? '160px auto' : '206px auto'};
    }
  `}
  font-size: ${(props) => props.theme.typography.size.mobile.heading}px;
  line-height: 1.4;
  text-align: center;
  font-family: ${(props) => props.theme.typography.font.npBold};
  margin: 0 auto;
  max-width: 300px;

  @media (min-width: ${breakpoint.min.sm}px) {
    font-size: ${(props) => props.theme.typography.size.desktop.heading}px;
    max-width: ${({ formLocation }) => (formLocation === 'footer' ? 600 : 700)}px;
  }
`

const Subtitle = styled.p`
  margin: ${({ theme }) => theme.spacing.default}px 0;
  text-align: center;

  @media (max-width: ${breakpoint.max.md}px) {
    display: none;
  }
`

const autocompleteContainerStyle = css`
  border-radius: 0;
  background: ${lightTheme.colors.danger};
`

const Container = styled.div<{ formLocation: string; useLargeContainer: boolean }>`
  background-image: ${({ formLocation }) =>
    formLocation === 'footer'
      ? 'url(/static/images/home-decorations-footer-mobile-min.png), url(/static/images/home-hero-background-mobile-min.jpg);'
      : 'url(/static/images/home-decorations-mobile-min.png), url(/static/images/home-hero-background-mobile-min.jpg);'};

  background-position: ${({ formLocation }) =>
    formLocation === 'footer' ? 'bottom center, center' : 'top center, center'};
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  padding: ${({ useLargeContainer }) => (useLargeContainer ? '45px 20px 175px' : '70px 20px')};

  @media (min-width: ${breakpoint.min.sm}px) {
    background-position: bottom center, center;
    background-size: ${breakpoint.min.xxl}px, cover;
    background-image: ${({ formLocation }) =>
      formLocation === 'footer'
        ? 'url(/static/images/home-decorations-footer-min.png), url(/static/images/home-hero-background-min.jpg);'
        : 'url(/static/images/home-decorations-min.png), url(/static/images/home-hero-background-min.jpg);'};
  }
`

export default LocationForm
